<div class="d-flex mt-4 align-items-center">
  <div class="pe-1">
    <h4 class="summary-bottom-margin">
      {{ 'common.additionalGuest' | translate }}
    </h4>
  </div>
</div>

@for (additionalGuest of additionalGuests; track additionalGuest; let isFirst = $first; let islast = $last; let i = $index) {
  <div class="row cb-event-additional-guest-layout">
    <div class="col-sm-6 custom-field form-group summary-padding">
      <label class="input-label" for="guestFirstName_{{ i }}" id="label_guestFirstName_{{ i }}">
        {{ 'customer.prename.label' | translate }}
      </label>
      <input
        maxlength="100"
        autocomplete="name"
        [(ngModel)]="additionalGuest.prename"
        placeholder="{{ 'customer.prename.placeholder' | translate }}"
        name="guestFirstName_{{ i }}"
        id="guestFirstName_{{ i }}"
        type="text"
        class="form-control cbw-input-field"
        required
        (blur)="updateAdditionalGuests()"
        [disabled]="disablePrenameLastnameEditing"
        attr.aria-disabled="{{ disablePrenameLastnameEditing }}"
        attr.aria-labelledby="label_guestFirstName_{{ i }}"
      />
    </div>
    <div class="col-sm-6 custom-field form-group summary-padding">
      <label class="input-label" for="guestLastName_{{ i }}" id="label_guestLastName_{{ i }}">{{ 'customer.lastname.label' | translate }}</label>
      <div class="d-flex">
        <input
          id="guestLastName"
          name="guestLastName"
          maxlength="100"
          autocomplete="name"
          [(ngModel)]="additionalGuest.lastname"
          placeholder="{{ 'customer.lastname.placeholder' | translate }}"
          type="text"
          class="form-control cbw-input-field"
          required
          (blur)="updateAdditionalGuests()"
          [disabled]="disablePrenameLastnameEditing"
          attr.aria-disabled="{{ disablePrenameLastnameEditing }}"
          attr.aria-labelledby="label_guestLastName_{{ i }}"
        />

        @if (isFirst && islast && additionalGuest.prename && additionalGuest.lastname && showDeleteButton) {
          <button
            type="button"
            class="btn btn-outline-danger remove-button mt-0 ms-2"
            (click)="removeOnlyGuestData(i)"
          >
            <fa-icon class="icon" icon="trash" />
          </button>
        }
        @if (!isFirst && showDeleteButton) {
          <button
            type="button"
            class="btn btn-outline-danger remove-button mt-0 ms-2"
            (click)="removeGuest(i)"
          >
            <fa-icon class="icon" icon="trash" />
          </button>
        }
      </div>
    </div>
  </div>
}

@if (showAddAdditionalGuestButton) {
  <div class="d-flex mt-4 align-items-center">
    <div class="pe-1">
      <a class="input-label summary-bottom-margin" (click)="addAdditionalGuest()">{{ 'common.addAdditionalGuest' | translate}}</a>
    </div>
  </div>
}
