<div class="d-flex calio-tel-input-container">
  <input
    #intlTelInput
    id="{{ name }}-cw-intel-tel"
    name="{{ name }}-cw-intel-tel"
    type="text"
    class="calio-tel-input"
    [ngClass]="cssClass"
    [formControl]="telInputControl"
    [ngClass]="{ 'is-invalid': intlTelInput.touched && intlTelInput.invalid }"
    (countrychange)="i18nizePhoneNumber()"
    (click)="onClick()"
    (blur)="onBlur()"
    attr.aria-invalid="{{ intlTelInput.touched && intlTelInput.invalid }}"
    attr.tabindex="{{ tabIndex }}"
  >
  @if (showRequestSmsCodeButton && !smsPhoneButtonDisabled) {
    <div role="button" class="sms-code-button" (click)="onReqestSmsCode()">
      <span>{{ 'summary_page_translations.request_sms_code' | translate }}</span>
    </div>
  }
  @if (showRequestSmsCodeButton && smsPhoneButtonDisabled) {
    <div role="button" class="sms-code-button disable-button">
      <span>{{ 'summary_page_translations.request_sms_code' | translate }}</span>
    </div>
  }
  @if (smsButtonloading) {
    <div role="status" class="loading">
      <fa-icon class="icon" icon="circle-notch" [spin]="true" />
    </div>
  }
</div>
