import { EventEmitter, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { WIDGET_CONSTANTS } from '../constants/widget-constants';
import { CustomBookingMessageTemplate, PartnerDao } from '../db-models/widget-conf-dao';
import { Partner } from '../models/global';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  protected readonly widgetConstants = WIDGET_CONSTANTS;
  protected readonly languages = this.widgetConstants.LANGUAGES;

  bookingLoader = new EventEmitter<boolean>();

  constructor(
    private translate: TranslateService
  ) {
  }

  /**
   * detect IE
   * returns version of IE or false, if browser is not Internet Explorer
   */
  detectIE() {
    const ua = window.navigator.userAgent;

    // Test values; Uncomment to check result …

    // IE 10
    // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

    // IE 11
    // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

    // IE 12 / Spartan
    // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

    // Edge (IE 12+)
    // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36
    // (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

    const msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      const rv = ua.indexOf('rv:');
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    const edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
  }

  getDefaultLanguage(partner: Partner | PartnerDao, inputLang: string = null): string {
    const partnerSupportedLang = partner?.supported_widget_languages || [this.widgetConstants.DEFAULT_LANG.split('_')[0]];
    const partnerDefaultLang = partner?.language_identifier || this.widgetConstants.DEFAULT_LANG;
    const browserlang = this.translate.getBrowserCultureLang() || '';
    let languageTobeSet = undefined;

    if (inputLang) {
      for (var i = 0; i < partnerSupportedLang.length; i++) {
        const language = partnerSupportedLang[i];
        if (inputLang.includes(language.toLowerCase())) {
          languageTobeSet = language;
          break;
        }
      }
    }

    if (!languageTobeSet) {
      for (var i = 0; i < partnerSupportedLang.length; i++) {
        const language = partnerSupportedLang[i];
        if (browserlang.includes(language.toLowerCase())) {
          languageTobeSet = language;
          break;
        }
      }
    }

    !languageTobeSet && (languageTobeSet = partnerDefaultLang.split('_')[0]);

    const langObj = this.languages.find(languages => languages.value === languageTobeSet);
    return langObj.locale || partnerDefaultLang;
  }

  sortBy(collection: Array<any>, property: string): Array<any> {
    collection.sort((a: any, b: any) => {
      if ((a[property] ? a[property].toLowerCase() : undefined) < (b[property] ? b[property].toLowerCase() : undefined)) {
        return -1;
      } else if (a[property] > b[property]) {
        return 1;
      } else {
        return 0;
      }
    });
    return collection;
  }

  sortByNumber(collection: Array<any>, property: string): Array<any> {
    collection.sort((a: any, b: any) => {
      if (Number(a[property]) < Number(b[property])) {
        return -1;
      } else if (Number(a[property]) > Number(b[property])) {
        return 1;
      } else {
        return 0;
      }
    });
    return collection;
  }

  dateAgoUtility(value: string): string {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (seconds < 29) {
        return 'Just now';
      } // less than 30 seconds ago will show as 'Just now'
      const intervals = {
        'year': 31536000,
        'month': 2592000,
        'week': 604800,
        'day': 86400,
        'hour': 3600,
        'minute': 60,
        'second': 1
      };
      let counter;
      for (const i in intervals) {
        if (intervals[i]) {
          counter = Math.floor(seconds / intervals[i]);
          if (counter > 0) {
            if (counter === 1) {
              let translation: string = this.translate.instant(`new_alert_component.x_${i + '_ago'}`);
              translation = translation.replace('x ', counter + ' ');
              return translation; // singular (1 day ago)
            } else {
              let translation: string = this.translate.instant(`new_alert_component.x_${i + 's_ago'}`);
              translation = translation.replace('x ', counter + ' ');
              return translation; // plural (2 days ago)
            }
          }
        }
      }
    }
    return value;
  }

  getQueryParamValues(urlparams: any, key1: string, key2: string): any {
    if (urlparams[key1]) {
      return urlparams[key1];
    } else if (urlparams[key2]) {
      return urlparams[key2];
    } else {
      return urlparams[key1];
    }
  }

  getQueryStringKey(key: string, query: any, isArray: boolean): any {
    const value: any = this.getQueryStringAsObject(query)[key];
    if (value && isArray) {
      delete value['length'];
      return Object.keys(value).map(function (_) {
        return value[_];
      });
    }
    return value;
  }

  getQueryStringAsObject(query: any) {
    var b, cv, e, k, ma, sk, v, r = {},
      d = function (v) {
        return decodeURIComponent(v).replace(/\+/g, ' ');
      }, // d(ecode) the v(alue)
      q = query, // suggested: q = decodeURIComponent(window.location.search.substring(1)),
      s = /([^&;=]+)=?([^&;]*)/g // original regex that does not allow for ; as a delimiter:   /([^&=]+)=?([^&]*)/g
    ;


    // ma(make array) out of the v(alue)
    ma = function (v) {
      // If the passed v(alue) hasn't been setup as an object
      if (typeof v != 'object') {
        // Grab the cv(current value) then setup the v(alue) as an object
        cv = v;
        v = {};
        v.length = 0;

        // If there was a cv(current value), .push it into the new v(alue)'s array
        //     NOTE: This may or may not be 100% logical to do... but it's better than loosing the original value
        if (cv) {
          Array.prototype.push.call(v, cv);
        }
      }
      return v;
    };

    // While we still have key-value e(ntries) from the q(uerystring) via the s(earch regex)...
    while (e = s.exec(q)) { // while((e = s.exec(q)) !== null) {
      // Collect the open b(racket) location (if any) then set the d(ecoded) v(alue) from the above split key-value e(ntry)
      b = e[1].indexOf('[');
      v = d(e[2]);

      // As long as this is NOT a hash[]-style key-value e(ntry)
      if (b < 0) { // b == "-1"
        // d(ecode) the simple k(ey)
        k = d(e[1]);

        // If the k(ey) already exists
        if (r[k]) {
          // ma(make array) out of the k(ey) then .push the v(alue) into the k(ey)'s array in the r(eturn value)
          r[k] = ma(r[k]);
          Array.prototype.push.call(r[k], v);
        }
        // Else this is a new k(ey), so just add the k(ey)/v(alue) into the r(eturn value)
        else {
          r[k] = v;
        }
      }
      // Else we've got ourselves a hash[]-style key-value e(ntry)
      else {
        // Collect the d(ecoded) k(ey) and the d(ecoded) sk(sub-key) based on the b(racket) locations
        k = d(e[1].slice(0, b));
        sk = d(e[1].slice(b + 1, e[1].indexOf(']', b)));

        // ma(make array) out of the k(ey)
        r[k] = ma(r[k]);

        // If we have a sk(sub-key), plug the v(alue) into it
        if (sk) {
          r[k][sk] = v;
        }
        // Else .push the v(alue) into the k(ey)'s array
        else {
          Array.prototype.push.call(r[k], v);
        }
      }
    }

    // # Return the r(eturn value)
    return r;
  }

  loadStripeJs() {
    try {
      const script = document.createElement('script');
      script.src = `https://js.stripe.com/v3/`;
      document.head.appendChild(script);
    } catch (ex) {
      LoggerService.error(ex);
    }
  }

  loadPaypalJs(clientId: string) {
    try {
      const script = document.createElement('script');
      script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}`;
      script.defer = true;
      document.head.appendChild(script);
    } catch (ex) {
      LoggerService.error(ex);
    }
  }

  get_random(list: any[]): any {
    return list[Math.floor((Math.random() * list.length))];
  }

  findClosest(dates, testDate) {
    const before = [];
    const after = [];
    const max = dates.length;
    for (let i = 0; i < max; i++) {
      const tar = dates[i];
      const arrDate: any = new Date(tar.day_year, tar.day_month, tar.day_number);
      // 3600 * 24 * 1000 = calculating milliseconds to days, for clarity.
      const diff = (arrDate - testDate) / (3600 * 24 * 1000);
      if (diff > 0) {
        before.push({diff: diff, index: i});
      } else {
        after.push({diff: diff, index: i});
      }
    }
    before.sort(function (a, b) {
      if (a.diff < b.diff) {
        return -1;
      }
      if (a.diff > b.diff) {
        return 1;
      }
      return 0;
    });

    after.sort(function (a, b) {
      if (a.diff > b.diff) {
        return -1;
      }
      if (a.diff < b.diff) {
        return 1;
      }
      return 0;
    });
    return {datesBefore: before, datesAfter: after};
  }

  findNearestDate(datesToBeChecked, dateToCheckFor) {
    let nearestDate;

    datesToBeChecked.forEach(date => {
      const diff = moment(date).diff(moment(dateToCheckFor), 'days');
      if (diff > 0) {
        if (nearestDate) {
          if (moment(date).diff(moment(nearestDate), 'days') < 0) {
            nearestDate = date;
          }
        } else {
          nearestDate = date;
        }
      }
    });

    return nearestDate;
  }

  replaceAll(textLabel: string, search: string, replacement: string): string {
    // return textLabel.replace(new RegExp(search, 'g'), replacement);
    return textLabel.split(search).join(replacement);
  }

  random(max: number, min: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  /**
   * To sort an array by specified field.
   * Usage: [].sort(sortArrayObjectsByField('sort_by_field_name))
   * @param property Sort field
   * @author Pratik Padia
   */
  sortArrayObjectsByField(property: string) {
    let sortOrder = 1;
    if (property[0] === '-') {
        sortOrder = -1;
        property = property.substring(1);
    }
    return (a: any, b: any) => {
      const valueA = typeof a[property] === 'string' ? a[property].toLowerCase() : a[property];
      const valueb = typeof b[property] === 'string' ? b[property].toLowerCase() : b[property];
      const result = (valueA < valueb) ? -1 : (valueA > valueb) ? 1 : 0;
      return result * sortOrder;
    };
  }

  /**
   * To randomly suffle an arrayelements of array
   * @param array Array wants to suffle
   * @returns Returns shuffled array
   */
  shuffle(array: any[]): any[] {
    let currentIndex = array.length;
    let randomIndex: number;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }
    return array;
  }

  /**
   * To find a template for the templates array
   * @param templates Templates array in which search needs to perform
   * @param identifier Template identifier need to be find from templates array
   * @returns Return template which mathces with identifier
   */
  findTemplate(widgetTemplates: CustomBookingMessageTemplate[], identifier: string): CustomBookingMessageTemplate | null {
    const template = widgetTemplates.find(template => template.identifier === identifier);
    if (template) {
      template.is_multi_language = 1;
      return template;
    }
    return null;
  }

  /**
   * Validate string is empty or not
   * @param value String value to check if it is empty or not
   * @returns Returns boolean weather string is emprt or not
   */
  isEmptyString(value: string): boolean {
    if (value === 'NULL' || value === 'null' || value === 'undefined' || value === 'UNDEFINED') {
      return true;
    }
    return false;
  }

  /**
   * Identify specific storage is available or not
   * @param type Type of storage to check if it is available or not
   * @returns boolean
   */
  storageAvailable(type: string): boolean {
    try {
      const storage = window?.[type];
      const x = '__calenso_storage_test__';

      storage.setItem(x, x);
      storage.removeItem(x);

      return true;
    } catch (e) {
      return false;
    }
  }

  redirectToUrl(url: string): void {
    var link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  /**
   * This function will replace space with + sign in email
   * @param email string
   * @returns string
   */
  fixEmailSpaceIssue(email: string): string {
    return email.replace(/ /g, "+");
  }
}
