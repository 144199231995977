import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { EventCartItem, GuestModel } from '../../../models/cart.model';
import { EventState } from '../../../models/state.model';
import { CustomEventService } from '../../../services/custom-event.service';

@Component({
    selector: 'app-additional-guest',
    templateUrl: './additional-guest.component.html',
    styleUrls: ['./additional-guest.component.scss'],
    standalone: true,
    imports: [FormsModule, FontAwesomeModule, TranslateModule]
})
export class AdditionalGuestComponent implements OnInit {

  showDeleteButton = true;
  showAddAdditionalGuestButton = true;
  disablePrenameLastnameEditing = false;

  @Input() eventState: EventState;
  @Input() cart?: EventCartItem[] = [];
  @Input() additionalGuests: GuestModel[] = [];
  @Input() numberOfAllowedGuest: Number = 0;

  @Output() updateAdditionalGuestsEvent: EventEmitter<GuestModel[]> = new EventEmitter();

  constructor(
    private customEventService: CustomEventService
  ) {
    this.customEventService.paypalOrderSuccessfullEvent.subscribe(() => {
      this.showAddAdditionalGuestButton = false;
      this.showDeleteButton = false;
      this.disablePrenameLastnameEditing = true;
    });
  }

  ngOnInit() {
    this.createAdditionalGuestArrayOfDefinedSize(this.eventState);
  }

  createAdditionalGuestArrayOfDefinedSize(eventState: EventState): void {
    this.additionalGuests = [];
    if (eventState && eventState.additional_guests_enabled === 1) {
      if (eventState.number_allowed_additional_guests > 0) {
        this.additionalGuests.push(new GuestModel('', ''));
      }

      // case when available_capacity is < 2 then there is no need to show button
      if (this.cart.length === 1) {
        this.showAddAdditionalGuestButton = (this.cart[0].available_regular_capacity > 2);

        // case when regular capacity is full and check if waiting list is enabled and have some seats then show add participant button
        if (this.cart[0].available_regular_capacity == 0 && this.cart[0].is_waitinglist_enabled) {
          this.showAddAdditionalGuestButton = (this.cart[0].available_waitinglist_capacity > 2);
        }
      } else {
        // case when regular capacity for all the slots are < 2 then there is no need to show button
        this.showAddAdditionalGuestButton = this.cart.every(cartItem => cartItem.available_regular_capacity > 2);

        // case when regular capacity for all the slots are < 2 then check for waiting list and show add participant button accordingly
        if (!this.showAddAdditionalGuestButton) {
          // Check if waiting list is enabled and regular capacity is full and waiting list capacity is > 2 for all the cart items then show button
          this.showAddAdditionalGuestButton = this.cart.every(cartItem =>
            cartItem.is_waitinglist_enabled &&
            cartItem.available_regular_capacity === 0 &&
            cartItem.available_waitinglist_capacity > 2
          );
        }
      }
    }
  }

  addAdditionalGuest(): void {
    if (this.cart.length === 1) {
      if (
        this.additionalGuests.length < (this.cart[0].available_regular_capacity - 1) &&
        this.additionalGuests.length < this.eventState.number_allowed_additional_guests
      ) {
        this.additionalGuests.push(new GuestModel('', ''));
        this.showAddAdditionalGuestButton = !(this.additionalGuests.length === this.cart[0].available_regular_capacity - 1);

        if (this.additionalGuests.length === this.eventState.number_allowed_additional_guests) {
          this.showAddAdditionalGuestButton = false;
        }
      } else if (this.cart[0].is_waitinglist_enabled) {
        let waitingListCapcaity = 0;
        if (this.cart[0].regular_booking_count === this.cart[0].regular_capacity) {
          // case when regular booking is already full and user is trying to book all the waiting list slots
          waitingListCapcaity = (this.cart[0].available_waitinglist_capacity - this.additionalGuests?.length - 1);
        } else {
          // keep: Will be needed when we allows to book regular seats + waiting list a time

          // case when regular booking and waiting list both has capacity to fill so will count total available capacity and minus
          // added guest and also minus 1 for the perosn who is booking an appointment
          // waitingListCapcaity = ((this.cart[0].waitinglist_available_capacity + this.cart[0].available_regular_capacity) - this.additionalGuests?.length - 1);
        }

        if (waitingListCapcaity) {
          this.additionalGuests.push(new GuestModel('', ''));
        }
        this.showAddAdditionalGuestButton = waitingListCapcaity > 1;
      }
    } else {
      // get minimum number of available regular capacity
      let regularCapacityArray = this.cart.map(cartItem => cartItem.available_regular_capacity).sort();
      let minRegularCapacity = regularCapacityArray[0] || 0;

      if (this.additionalGuests.length < (minRegularCapacity - 1)) {
        this.additionalGuests.push(new GuestModel('', ''));
        this.showAddAdditionalGuestButton = !(this.additionalGuests.length >= (minRegularCapacity - 1));
      } else {
        // count minimum number of available waiting list capacity
        const minimumWaitingListCapacityArray = this.cart.map(cartItem =>
          cartItem.is_waitinglist_enabled
            ? (cartItem.available_waitinglist_capacity - this.additionalGuests?.length - 1)
            : 0
          ).sort();
        let waitingListCapcaity = minimumWaitingListCapacityArray[0] || 0;

        if (waitingListCapcaity) {
          this.additionalGuests.push(new GuestModel('', ''));
          this.showAddAdditionalGuestButton = waitingListCapcaity > 1;
        }
      }
    }
  }

  removeGuest(index: number): void {
    this.additionalGuests.splice(index, 1);
    this.showAddAdditionalGuestButton = !(this.additionalGuests.length >= this.eventState.number_allowed_additional_guests);
    this.updateAdditionalGuests();
  }

  removeOnlyGuestData(index: number): void {
    this.additionalGuests = [new GuestModel('', '')];
    this.updateAdditionalGuestsEvent.emit([]);
  }

  updateAdditionalGuests(): void {
    if (this.disablePrenameLastnameEditing) {
      return;
    }

    const totalGuests = this.additionalGuests.filter(guests => (guests?.prename.trim() !== '' && guests?.lastname.trim() !== ''));

    if (totalGuests?.length) {
      this.updateAdditionalGuestsEvent.emit(totalGuests);
    }
  }
}
