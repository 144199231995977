import {AppointmentServiceDao} from "./appointment-service-dao";

export class CustomFieldsDao {
  id: number;
  placeholder: string;
  label: string;
  name: string;
  required: number;
  regex: string;
  validation_message: string;
  partner_id: number;
  type: string;
  position: number;
  is_hidden: number;
  customer_custom_field_type_id: number;
  deleted_date?: Date;
  deleted?: number;
  sort_alphabetically: number;
  alphabetically_sorted: number;
  apply_to_appointments: number;
  apply_to_events: number;
  apply_to_resource_types: number;
  custom_field_options: CustomFieldOptions[];
  title: string;
  is_multi_language: number;
  child_custom_field_id: number;
  parent_custom_field_id: number;
  parent_custom_field: CustomFieldsDao;
  child_custom_field: CustomFieldsDao;
  _translations?: any[];
  child_event: CustomFieldChildEvent;
  child_event_id: number;
  is_multiple_select: number|string;
  is_multiple_file: number;
  is_read_only: number;
  supported_mime_type: string | string[];
  format: string;
  color: string;
  is_end_of_process: number;
  custom_field_dependency_enabled: number;
  deleted_by_worker_id: number;
  hide_in_email: number;
  always_show: number;
  is_entity_generator: number;
  allowed_entities: number;
  hide_on_landingpage: number;
  send_cc_mail: number;
  appointment_services?: AppointmentServiceDao[];
  is_qualification_question: number;
  max_characters: number;
  min_characters: number;

  // client side properties
  customSupportedMimeType?: string;
  errorMessage?: string;
  maxFileErrorMessage?: string;
  invalidFileTypeErrorMessage?: string;
  showDependenciesGhostElement? = false;
  customFieldDependencies?: CustomFieldsDao[] = [];
  uploadedFiles?: CustomFieldFileUploadModel[] = [];
}

export class CustomFieldOptions {
  id: number;
  label: string;
  custom_field_id: number;
  partner_id: number;
  position: number;
  deleted_date?: Date;
  deleted?: number;
  dependencies: string;
  title: string;
  image: string;
  number_default: number;
  number_min: number;
  number_max: number;
  is_fullscreen_image: number;
  option_value: any;
  _translations: any;
  youtube_url: string;
  info_url: string;
  is_multi_language: number;
  deleted_by_worker_id: number;
  supported_appointment_service_ids: string;
  number_of_columns: number;
  is_end_of_process: number;
}

export class CustomFieldChildEvent {
  id: number;
  title: string;
  description: string;
  street: string;
  zip: string;
  city: string;
  url: string;
  start: Date | string;
  end: Date | string;
  image: string;
  partner_id: number;
  price: number;
  add_appointment_for_workers: number;
  status: number;
  category: string;
  additional_guests_enabled: number;
  number_allowed_additional_guests: number;
  is_online_payment_enabled: number;
  hide_capacity: number;
  place: string;
  alias: string;
  is_secret: number;
  send_email_reminder: number;
  send_sms_reminder: number;
  create_worker_calendar_entry: number;
  cancellation_condition_text: string;
  add_cancellation_link_to_notifications: number;
  duration: number;
  future_booking_threshold: number;
  uuid: string;
}

export class CustomFieldFileUploadModel {
  type: string;
  size: number;
  partner_id: number;
  uuid: string;
  virus_check_performed: number;
  filename: string;
  full_path: string;
  id: number;
  origin_filename: string;
}

export class CustomFieldValueModel {
  id: number;
  customer_id: number;
  custom_field_id: number;
  appointment_id: number;
  customers_slots_id: number;
  value: any;
  value_hash: string;
  customer: CustomerDbModel;
  custom_field: any;
}

export class CustomerDbModel {
  id: number;
  partner_id: number;
  number: number;
  customer_type_id: number;
  skype: string;
  url: string;
  facebook: string;
  twitter: string;
  mwst: string;
  hr: string;
  ust: string;
  employee_count: number;
  tags: any;
  country_id: number;
  address_addition: string;
  contact_person_id: number;
  worker_id: number;
  language_id: number;
  notification_preference: string;
  created: Date;
  birthday: Date;
  smart_action_2_notification_count: number;
  uuid: string;
  prename: string;
  lastname: string;
  email: string;
  phone: string;
  street: string;
  zip: string;
  city: string;
  name: string;
  remarks: string;
  mobile: string;
  email_hash: string;
  prename_hash: string;
  lastname_hash: string;
  phone_hash: string;
  street_hash: string;
  zip_hash: string;
  city_hash: string;
  name_hash: string;
  remarks_hash: string;
  mobile_hash: string;
  company_name: string;
}
