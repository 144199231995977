import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { GTM_EVENTS } from '../constants/widget-constants';
import { CustomFieldFileUploadModel, CustomFieldValueModel, CustomFieldsDao } from '../db-models/appointment-custom-fields-dao';
import { AppointmentServiceDao } from '../db-models/appointment-service-dao';
import { CouponDbModel } from '../db-models/coupon-db.model';
import { EventDao } from '../db-models/event-dao';
import { EventDateDao } from '../db-models/event-date-dao';
import { DayWiseFreeSlotsDao } from '../db-models/free-appointment-dao';
import { StoresDao } from '../db-models/stores-dao';
import { UserDao } from '../db-models/user-data-dao';
import { WidgetAppointmentGroupModel } from '../db-models/widget-appointment-group.dao';
import { EventBookingModel } from '../models/booking.model';
import { AppointmentBookItem, EventBookItem, ResourceBookItem } from '../models/cart.model';
import { MeetingTypeModel } from '../models/meeting-types.model';
import { GoogleAnalyticsService } from './google-analytics.service';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root',
})
export class FormsService {
  constructor(
    public http: HttpClient,
    private googleAnalyticsService: GoogleAnalyticsService,
    private helperService: HelperService
  ) { }

  imageUploaded = true;

  downloadAppointmentByUUID(uuid: string): Observable<{ file: string; type: string }> {
    return this.http.get<{ file: string; type: string }>(`${environment.apiUrl}appointments/download/${uuid}`);
  }

  downloadEventByUUID(uuid: string): Observable<{ file: string; type: string }> {
    return this.http.get<{ file: string; type: string }>(`${environment.apiUrl}customers_slots/download/${uuid}`);
  }

  getStores(body: { appointment_service_id: number | number[], worker_id?: number }): Observable<StoresDao[]> {
    return this.http.post<StoresDao[]>(environment.apiUrl + 'stores/stores_with_workers', body).pipe(
      switchMap(stores => {
        return of(stores?.length ? this.helperService.sortByNumber(stores, 'position') : []);
      })
    );
  }

  getStoresByPostCode(postcode: string, sort = true): Observable<StoresDao[]> {
    return this.http.post<StoresDao[]>(environment.apiUrl + 'stores/store_by_postcode', { postcode }).pipe(
      switchMap(result => {
        const stores = result?.map(store => {
          if (store?.store) {
            return {
              postcode_details: {
                city: store.city,
                postcode: store.postcode
              },
              ...store.store
            }
          }

          return store;
        });


        if (stores?.length) {
          return sort ? of(this.helperService.sortByNumber(stores, 'position')) : of(stores);
        }

        return of([]);
      })
    );
  }

  getAppointmentSevices(storeId: number, workerId: number): Observable<AppointmentServiceDao[]> {
    const body = { store_id: storeId, worker_id: workerId };
    return this.http.post<AppointmentServiceDao[]>(`${environment.apiUrl}appointment_services/appointment_services_by_store`, body);
  }

  getInactiveWeekDays(workerId: number, storeId: number, appointmentIds: string): Observable<number[]> {
    const body = {
      worker_id: workerId,
      appointment_ids: appointmentIds,
      store_id: storeId,
    };
    return this.http.post<number[]>(environment.apiUrl + 'availabilities/inactive_week_days', body);
  }

  getDaysWiseFreeSlots(
    workerId: number,
    storeId: number,
    appointmentIds: string,
    date: string,
    isInternal: boolean,
    conferenceUuid = undefined,
    debug = undefined,
    token: string
  ): Observable<DayWiseFreeSlotsDao> {
    let body: Params;
    if (conferenceUuid) {
      body = {
        start: date,
        booking_link_uuid: conferenceUuid,
        is_internal: isInternal
      };
    } else {
      body = {
        worker_id: workerId,
        appointment_ids: appointmentIds,
        store_id: storeId,
        start: date,
        is_internal: isInternal
      };
    }

    if (debug === 'true') {
      body.debug = debug;
      body.token = token;
    }

    return this.http.post<DayWiseFreeSlotsDao>(`${environment.apiUrl}availabilities/free_appointments`, body);
  }

  getPartnerQualificationQuestions(): Observable<CustomFieldsDao[]> {
    return this.http.get<CustomFieldsDao[]>(environment.apiUrl + 'custom_fields/qualification_based_custom_fields');
  }

  getAppointmentCustomFields(servicesId: number[]): Observable<CustomFieldsDao[]> {
    const body = { appointment_service_ids: servicesId };
    return this.http.post<CustomFieldsDao[]>(environment.apiUrl + 'custom_fields/custom_fields_by_appointment_service_ids', body);
  }

  bookAppointments(bookingItems: AppointmentBookItem[]): Observable<{
    success: boolean;
    errors: { message: string }[];
    appointment: any;
  }> {
    // preparing gtm params for gtm event
    const gtmParams = { appointments: [] };
    bookingItems.forEach(bookingItem => {
      let appointmentServiceName = '';
      if (bookingItem?.referenceServices?.length > 0) {
        appointmentServiceName = bookingItem?.referenceServices
          .map((item) => item.serviceName)
          .join(',');
      }

      gtmParams.appointments.push({
        appointment_service_id: bookingItem.services,
        appointment_service_name: appointmentServiceName,
        store_id: bookingItem.store_id,
        store_name: bookingItem.store_name,
        worker_id: bookingItem.worker_id,
        resource_name: bookingItem.resource_name,
        worker_email: bookingItem.worker_email
      });
    });

    this.googleAnalyticsService.emitBookingEvent(GTM_EVENTS.APPOINTMENT_BOOKING, gtmParams);

    return this.http.post<{
      success: boolean;
      errors: { message: string }[];
      appointment: any;
    }>(environment.apiUrl + 'appointments/book', bookingItems);
  }

  getEventCustomFields(eventUuid: string | number): Observable<CustomFieldsDao[]> {
    const body: { event_uuid: string | number } = { event_uuid: eventUuid };
    return this.http.post<CustomFieldsDao[]>(
      environment.apiUrl + 'custom_fields/custom_fields_by_event',
      body
    );
  }

  getCustomFieldValues(customerUuid: number): Observable<CustomFieldValueModel[]> {
    const body = { customer_uuid: customerUuid };
    return this.http.post<CustomFieldValueModel[]>(environment.apiUrl + 'customer_custom_fields/customer', body);
  }

  getEvents(limit: number, offset: number): Observable<{ events: EventDao[], total_count: number }> {
    const body = { status: { value: 1, operator: '=' } };
    const params = new HttpParams()
      .set('limit', limit)
      .set('offset', offset);
    return this.http.post<{ events: EventDao[], total_count: number }>(environment.apiUrl + 'events/filter', body, { params });
  }

  getEventByIdOrUuid(uuid: number | string): Observable<EventDao> {
    return this.http.get<{ event: EventDao }>(`${environment.apiUrl}events/${uuid}`).pipe(
      map(eventData => eventData?.event)
    );
  }

  getEventSlotsByEventIdAndDate(
    eventUuid: string | number,
    date: string
  ): Observable<{ slotDates: (string | number)[]; slotsCount: number }> {
    const body = { event_uuid: eventUuid, date: date };
    return this.http.post<{ slotDates: string[]; slotsCount: number }>(environment.apiUrl + 'events/slot_dates', body);
  }

  bookEvents(bookingItems: EventBookItem[]): Observable<{
    success: boolean;
    notificationHelperArray: EventBookingModel[];
    errors: { message: string }[];
    bookingErrors?: { code: number; message: string }[];
  }> {
    // preparing gtm params for gtm events
    const gtmParams = { events: [] };
    bookingItems.forEach(bookingItem => {
      gtmParams.events.push({
        event_uuid: bookingItem.event_id,
        event_category: bookingItem.category,
        event_title: bookingItem?.event_name || null,
        worker_id: bookingItem?.worker_id || null
      });
    });
    this.googleAnalyticsService.emitBookingEvent(GTM_EVENTS.EVENT_BOOKING, gtmParams);

    return this.http.post<{
      success: boolean;
      notificationHelperArray: EventBookingModel[];
      errors: { message: string }[];
      bookingErrors?: { code: number; message: string }[];
    }>(environment.apiUrl + 'events/book', bookingItems);
  }

  getMeetingTypesByAppointmentServiceIds(
    worker_id: number, selected_appointment_service_ids: number[], booking_link_uuid?: string
  ): Observable<MeetingTypeModel[]> {
    const body: {
      worker_id?: number;
      selected_appointment_service_ids?: number[];
      booking_link_uuid?: string;
    } = {};

    if (booking_link_uuid) {
      booking_link_uuid && (body.booking_link_uuid = booking_link_uuid);
    } else {
      worker_id && (body.worker_id = worker_id);
      selected_appointment_service_ids && (body.selected_appointment_service_ids = selected_appointment_service_ids);
    }

    return this.http.post<MeetingTypeModel[]>(environment.apiUrl + 'meeting_types/list', body).pipe(
      switchMap(meetingTypes => {
        if (Array.isArray(meetingTypes)) {
          return of(meetingTypes?.length ? meetingTypes : []);
        }
        return of(meetingTypes ? Object.values(meetingTypes) as MeetingTypeModel[] : []);
      })
    );
  }

  getMeetingTypesBySlotWorkerId(event_uuid: string | number): Observable<MeetingTypeModel[]> {
    return this.http.get<MeetingTypeModel[]>(environment.apiUrl + 'meeting_types/event_list/' + event_uuid).pipe(
      switchMap(meetingTypes => {
        if (Array.isArray(meetingTypes)) {
          return of(meetingTypes?.length ? meetingTypes : []);
        }
        return of(meetingTypes ? Object.values(meetingTypes) as MeetingTypeModel[] : []);
      })
    );
  }

  // TODO(Nishtha - Pratik): Remove this
  getResourceTypes() {
    return this.http.get(environment.apiUrl + 'resource_types');
  }

  // TODO(Nishtha - Pratik): Remove this
  getFreeResources(requestBody: object) {
    return this.http.post(environment.apiUrl + 'resources/freeFromResourceTypeGroupedBySequenceType', requestBody)
      .pipe(map((resp) => resp['resources']['parallel'][0]));
  }

  // TODO(Nishtha - Pratik): Remove this
  getFreeResourcesJson() {
    return JSON.parse(
      `[ { "isValid": true,
        "start": "2017-12-15T06:00:00",
        "end": "2017-12-17T22:00:00",
        "short_start": "0600",
        "resources": [ {
             "id": 2,
             "resource_type_id": 2,
             "name": "Room1",
             "description": "Room with sight",
             "min_length": 1455,
             "max_length": 5760
            } ],
            "resource_sequence_type_id": 1
          }, {
            "isValid": true,
            "start": "2017-12-15T06:00:00",
            "end": "2017-12-17T22:00:00",
            "short_start": "0600",
            "resources": [ {
               "id": 3,
               "resource_type_id": 2,
               "name": "Room2",
               "description": "Room without sight",
               "min_length": 1455,
               "max_length": 5760
              } ],"resource_sequence_type_id": 5 } ]`
    );
  }

  // TODO(Nishtah - Pratik): Remove this
  getResourcesCustomFields(resourceTypeId: number) {
    return this.http.get(
      environment.apiUrl +
      'custom_fields/custom_fields_by_resource_types/' +
      resourceTypeId
    );
  }

  // TODO(Nishtah - Pratik): Remove this
  bookResource(bookingItems: ResourceBookItem[]) {
    return this.http
      .post(
        environment.apiUrl + 'resources/book_resource_action/',
        bookingItems
      )
      .pipe(map((resp) => resp['success']));
  }

  getSlotsByDate(eventUuid: string | number, date: string): Observable<EventDateDao[]> {
    const body = { event_uuid: eventUuid, date: date };
    return this.http.post<{ slotsByDate: EventDateDao[] }>(`${environment.apiUrl}events/slots`, body).pipe(
      map(dates => dates.slotsByDate)
    );
  }

  applyCoupon(coupon: string): Observable<CouponDbModel> {
    const body = { coupon };
    return this.http.post<CouponDbModel>(`${environment.apiUrl}coupons/verify`, body);
  }

  getFilesByFileIds(fileIds: number[]): Observable<CustomFieldFileUploadModel[]> {
    const body = { ids: fileIds };
    return this.http.post<CustomFieldFileUploadModel[]>(environment.apiUrl + 'files/get_files_by_ids', body);
  }

  uploadCustomFieldFiles(fileList: FileList, field: CustomFieldsDao): Observable<
    CustomFieldFileUploadModel[] | { errors: { code: string, message: string } }
  > {
    const formData = new FormData();
    for (let i = 0; i < fileList.length; i++) {
      formData.append('file[]', fileList[i]);
    }
    formData.append('custom_field_id', field.id.toString());
    return this.http.post<CustomFieldFileUploadModel[]>(environment.apiUrl + 'files', formData);
  }

  deleteFile(file: CustomFieldFileUploadModel): Observable<{ success: boolean }> {
    return this.http.delete<{ success: boolean }>(environment.apiUrl + 'files/' + file.uuid);
  }

  createStripePaymentIntent(amount: number): Observable<{ secret: string }> {
    const body = { amount };
    return this.http.post<{ secret: string }>(environment.apiUrl + 'stripe/payment_intent_connected_account', body);
  }

  getStripeAccountId(): Observable<{ stripeEnabled: boolean; accountId: string; }> {
    return this.http.get<{ stripeEnabled: boolean; accountId: string }>(environment.apiUrl + 'stripe/verify');
  }

  getWidgetAppointmentGroups(): Observable<WidgetAppointmentGroupModel[]> {
    return this.http.get<WidgetAppointmentGroupModel[]>(`${environment.apiUrl}widget_appointment_groups`);
  }

  getStoreWiseWidgetAppointmentGroups(store_id: number): Observable<WidgetAppointmentGroupModel[]> {
    const body = { store_id };
    return this.http.post<WidgetAppointmentGroupModel[]>(`${environment.apiUrl}widget_appointment_groups/by_store`, body);
  }

  verifyPhoneNumber(mobile: string): Observable<{ verified: boolean; count: number }> {
    const body = { mobile };
    return this.http.post<{ verified: boolean; count: number }>(environment.apiUrl + 'partners/verify_mobile_number', body);
  }

  requestSmsCode(mobile: string): Observable<{ requested: boolean; count: number }> {
    const body = { mobile };
    return this.http.post<{ requested: boolean; count: number }>(environment.apiUrl + 'partners/request_sms_code', body);
  }

  confirmSmsCode(mobile: string, verification_code: number): Observable<{ verified: boolean; count: number }> {
    const body = { mobile, verification_code };
    return this.http.post<{ verified: boolean; count: number }>(environment.apiUrl + 'partners/confirm_sms_code', body);
  }

  filterWorkers(body: {
    auth0_user_id?: { value: string; operator: string; };
    is_lead_generator?: { value: string; operator: string; };
  }): Observable<UserDao[]> {
    return this.http.post<UserDao[]>(`${environment.apiUrl}workers/filter`, body).pipe(
      switchMap(workers => {
        return of(workers || []);
      })
    );
  }

  getCustomFieldsByIds(ids: number[]): Observable<CustomFieldsDao[]> {
    const body = { ids };
    return this.http.post<CustomFieldsDao[]>(`${environment.apiUrl}custom_fields/get_by_ids`, body).pipe(
      switchMap(customFields => {
        customFields?.forEach(customField => {
          customField.position = Number(customField.position);
        });

        return of(customFields);
      })
    );
  }
}
