import { HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthTokenService } from '../services/auth-token.service';

@Injectable({
  providedIn: 'root'
})
export class AuthTokenInterceptor {
  constructor(
    private authTokenService: AuthTokenService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const authToken = this.authTokenService.getAuthToken();
    if (authToken) {
      const headers: { [name: string]: string } = {};
      headers['Authorization'] = `Bearer ${authToken}`;
      headers['X-Calenso-Auth'] = 'true';

      const tokenReq = req.clone({
        setHeaders: headers
      });
      return next.handle(tokenReq);
    }
    return next.handle(req);
  }
}
