import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {StoreDbModel} from '../db-models/stores-dao';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  constructor(
    public http: HttpClient
  ) {
  }

  getMappedEventStores(): Observable<StoreDbModel[]> {
    return this.http.get<StoreDbModel[]>(environment.apiUrl + 'stores/get_event_stores');
  }
}
