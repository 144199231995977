export const en_US = {
  customer_type: 'Customer type',
  manage_appointment_now: 'Manage appointment now',
  maintenance_page_title: 'Calenso is just being improved for you',
  maintenance_page_subtitle: 'Calenso is being updated to the latest version. Please be patient and check back in a few minutes.',
  maintenance_page_button: 'To the status page',
  load_more: 'Load more',
  show_more: 'Show More',
  internal_label: 'Internal',
  check_availability_for_anyone: 'Check availabilitites for anyone',
  store_not_found: 'No store was found',
  appointment: 'Appointments',
  event: 'Group-Appointments',
  resource: 'Resources',
  morgen: 'morning',
  vormittag: 'forenoon',
  mittag: 'noon',
  nachmittag: 'afternoon',
  abend: 'evening',
  nacht: 'night',
  free: 'free',
  hoursShort: '',
  minutesShort: 'm',
  mondayLong: 'Montag',
  day1: 'MO',
  tuesdayLong: 'Tuesday',
  day2: 'TU',
  wednesdayLong: 'Wednesday',
  day3: 'WE',
  thursdayLong: 'Thursday',
  day4: 'TH',
  fridayLong: 'Friday',
  day5: 'FR',
  saturdayLong: 'Saturday',
  day6: 'SA',
  sundayLong: 'Sunday',
  day0: 'SU',
  Januar: 'January',
  Februar: 'February',
  März: 'March',
  April: 'April',
  Mai: 'May',
  Juni: 'June',
  Juli: 'July',
  August: 'August',
  September: 'September',
  Oktober: 'October',
  November: 'November',
  Dezember: 'December',
  du: 'you',
  sie: 'You',
  havingFormDu: 'have',
  havingFormSie: 'have',
  confirm: {
    title: 'Confirm order',
    confirmationText: 'You have {{ cartLength }} appointments in your cart. Do you really want to book all of them now?',
    confirmationTextSie: 'Your have {{ cartLength }} appointments in your cart. Do you really want to book all of them now?',
    ok: 'Book',
    cancel: 'Cancel'
  },
  internal: {
    search_customer: 'Search existing customer and prefill form',
    selectPlaceholder: 'Search customer',
    customerDetails: 'Customer details',
    noCustomer: 'There is no customers configured',
    create_customer: 'Create Customer'
  },
  common: {
    report_bug: 'Report bug',
    subscription_error_title: 'No subscription!',
    subscription_error_desc: 'Sorry, no subscription could be found. Please contact Calenso Support.',
    next: 'Next',
    previous: 'Previous',
    reset: 'Reset application',
    resetSuccess: 'The booking software was reset successfully. Data that was stored temporarily is now gone.',
    loading: 'Loading...',
    durationHour: 'h',
    durationMinute: 'm',
    clock: 'h',
    disclaimer: 'This service is provided by <a href=\'https://www.calenso.com\' class=\'cal-link\' target=\'_blank\'>Calenso</a>.',
    book: 'Book',
    choseBookType: 'What would you like to book?',
    company: 'Company',
    prename: 'First name',
    lastname: 'Last name',
    email: 'E-Mail',
    phone: 'Phone',
    address: 'Address',
    createAccount: 'Create customer account (user account with all registrations)',
    noDateError: 'The requested appointment was booked by someone else, go back and select a new appointment.',
    devNotice: 'The booking widget is connected to the DEV backend system. Be careful!',
    store_access_warning: 'Access to the browser memory is restricted due to your privacy settings. Not all functions of the booking widget may be available.',
    additionalGuest: 'Additional participants',
    addAdditionalGuest: 'Add participants',
    additionalGuestCartLabel: '{{ number }} additional guest',
    additionalGuestsCartLabel: '{{ number }} additional guests',
    widget_disabled: 'The booking widget is currently disabled',
    widget_disabled_description: 'The booking widget is temporary disabled. Please contact the Calenso support (Error-Code: PMT-01).',
    no_workers_widget_disabled_description: 'No appointments can currently be booked because no resource is enabled.',
    workers_not_available: 'No bookable service found',
    workers_not_available_description: 'No bookable service was recorded. Please make sure that at least one service is available and assigned to a bookable resource (ability). Entering a service category is not sufficient to be bookable.',
    price_from_label: 'from',
    close: 'Close',
    configuration_error: 'Configuration error',
    resource_is_not_bookable: 'The selected resource is not bookable.',
    verification_token_missing: 'The booking widget is used in internal mode and thus requires a verification token. Please contact Calenso support.',
    invalid_verification_code: 'Accessing the widget in internal mode requires a valid token. Please ensure the token is valid and try again.',
    internal_disabled: 'The internal booking widget is disabled.',
    evnet_disabled_error: 'Requested event is disabled by Partner, please contact your Administrator.',
    conference_invalid_link: "It seems like the conference link provided is invalid. Please double-check the link and try again.",
    conference_expired_link: "We're sorry, but this conference link has expired. Please contact the organizer for further assistance.",
    conference_not_belonging: "Oops, it looks like this conference is linked to a different partner.",
    conference_booked: "Whoops, it seems this conference link has already been used and booked. If you have any questions or require assistance, feel free to reach out to the organizers."
  },
  appointments: {
    step1: {
      distance_from_store: '{{ distance }} km away',
      search_store_postcode_helper_text: 'Enter postal code to search for nearest store',
      search_services_helper_text: 'Enter a search term to search for services',
      title: 'Services',
      chooseStore: 'Select store',
      chooseService: 'Select service',
      nextButton: 'Next',
      search_store_by_postcode_placeholder: 'Insert postal code to search for correct store',
      create_lead: 'Opportunity MH',
      expert_cap_button_label: 'Allocate to an expert CAP',
      appointment_booking_not_available_for_zipcode: 'Sorry, appointment booking is not available for this zip code. Create a new customer so that a CAP can contact them by phone.'
    },
    step2: {
      title: 'Resource',
      chooseEmployee: 'Choose a free resource',
      name: 'Name',
      function: 'Function',
      noSpecificWorker: 'Anybody',
      noSpecificFunction: 'All functions',
      nextButton: 'Choose date/time'
    },
    step3: {
      title: 'Date',
      chooseDate: 'Choose preferred date',
      morning: 'Morning',
      forenoon: 'Forenoon',
      noon: 'Noon',
      afternoon: 'Afternoon',
      evening: 'Evening',
      night: 'Night',
      infoMsg: 'Please note it is possible to select multiple appointments. Just choose the appointments you like via checkbox. The appointments then are added to cart.',
      appointment: 'Appointment',
      employee: 'Resource',
      proposals: 'Free appointments',
      noFreeAppointments: 'Unfortunately there are no free appointments on that day!',
      nextButton: 'Next',
      cartNotSupported: 'This provider only supports one slot registration per booking.',
      cartSupported: 'This provider supports multiple registrations per booking.',
      workerSelectLable: 'Select Personal Advisor:',
      workerSelectLinkPrefix: 'Didn\'t find an appointment with your advisor?',
      workerSelectShowAllLinkPostfix: 'Show all advisors',
      workerSelectHideAllLinkPostfix: 'Hide all advisors'
    },
    step4: {
      comment_helper_text: 'Maximum 2000 characters',
      title: 'Finish',
      summary: 'Summary',
      company: 'Company',
      store: 'Store',
      employee: 'Resource',
      bookedServices: 'Service',
      duration: 'Duration',
      price: 'Price',
      finalPrice: 'Costs',
      total: 'Total',
      yourData: 'Your data',
      disclaimer: 'Please insert your personal contact details. All fields marked with * are mandatory.',
      prename: {
        label: 'Prename',
        placeholder: 'Prename',
        error: {
          minChars: 'The first name must at least contain {{ requiredLength }} characters.',
          required: 'Please insert a first name.'
        }
      },
      lastname: {
        label: 'Last name',
        placeholder: 'Last name',
        error: {
          minChars: 'The last name must at least contain {{ requiredLength }} characters.',
          required: 'Please insert a last name.'
        }
      },
      phone: {
        label: 'Mobilephone',
        placeholder: '+4179xxxxxxx',
        error: {
          pattern: 'The telephone number can only contain digits.',
          minChars: 'The telephone number must at least contain {{ requiredLength }} digits.',
          required: 'Please insert a telephone number.'
        }
      },
      eMail: {
        label: 'Email',
        error: {
          pattern: 'Please insert a valid email address.',
          minChars: 'The email address must at least contain {{ requiredLength }} characters.',
          required: 'Please insert an email address.'
        }
      },
      message: {
        label: 'Message',
        placeholder: ''
      },
      internal_comment: {
        label: 'Internal Comment',
        placeholder: 'Add comments for internal use',
        comment_helper_text: 'Maximum 5000 characters.'
      },
      company_name: {
        label: 'Company Name',
        error: {
          minChars: 'This field requires at least {{ requiredLength }} characters.'
        }
      },
      customFields: {
        defaultOption: 'Please choose an option',
        error: {
          minChars: 'This field requires at least {{ requiredLength }} characters.',
          maxChars: 'This field allows a maximum of {{ requiredLength }} characters.',
          required: 'This field is mandatory'
        }
      },
      cart: {
        emptyCartWarning: 'There are currently no appointments in your cart.',
        removeIcon: 'Remove appointment from cart.',
        waiting_list: 'Waiting list'
      },
      numberSelect: {
        increase: 'Increase value',
        decrease: 'Decrease value'
      }
    },
    finalpage: {
      successTitle: 'You successfully booked the appointment(s)',
      successMessageDu: 'The appointment booking was successfully transmitted. A confirmation was sent either via e-mail or via SMS. <br> <br> If for any reason you can not attend the event, we ask you to cancel participation via the cancellation link in the email or in the SMS.',
      successMessageSie: 'The appointment booking was successfully transmitted. A confirmation was sent either via e-mail or via SMS. <br> <br> If for any reason you can not attend the event, we ask you to cancel participation via the cancellation link in the email or in the SMS.',
      errorTitle: 'An error has occurred.',
      errorMessage: 'The date could not be booked. Please try again.',
      errorMessageDu: 'Sorry, the appointment could not be booked. Please try again.',
      errorMessageSie: 'Sorry, the appointment could not be booked. Please try again.',
      bookNextAppointment: 'Book additional appointment',
      bookAppointmentAgain: 'Go back'
    },
    error: {
      noworkers: 'Unfortunately, this provider did not define any bookable resources yet.'
    }
  },
  events: {
    list: "List",
    grid: "Grid",
    noEvents: "This provider did not specify any events yet.",
    hidden_events_message: "The events of this partner are hidden. Please use the direct link to the event you received from the partner in order to register.",
    filter_result_null: "There are no results that match your criteria.",
    step1: {
      title: "Events",
      moreInformation: "More information",
      noFreeSlots: "No more free slots",
      noEvents: "Es wurden noch keine Events erstellt.",
      showDescription: "Show whole description",
      hideDescription: "Hide description",
      book: "Book",
      noPlaceDefined: "No place defined",
      store_filter: "Store filter",
      category_filter: "Category filter",
      all_categories: "All"
    },
    step2: {
      title: 'Date',
      slots: 'Slots',
      chooseDate: 'Choose prefered date',
      booked: 'Booked',
      capacity: 'Available',
      noFreeDates: 'There are no free events for this partner',
      bookedOut: 'This slot is already booked out',
      cartNotSupported: 'This provider only supports one slot registration per booking.',
      cartSupported: 'This provider supports multiple registrations per booking.',
      alreadyBookedByUser: 'You already booked this slot. In case of problems please reset the application.',
      waitinglist: 'waiting list: {{ capacity }} free',
      fully_booked: "Fully booked",
      regular_count_singular: '<b>1</b> free seat',
      regular_count_plural: '<b>{{ number }}</b> free seats',
      waiting_count_singular: '<b>1</b> free waiting list place',
      waiting_count_plural: '<b>{{ number }}</b> free waiting list places',
      free_seats: 'Free seats',
      free_waiting_seats: 'Free waiting list places'
    },
    step3: {
      title: 'Finish',
      summary: 'Summary',
      company: 'Company',
      store: 'Store',
      employee: 'Resource',
      bookedEvent: 'Event',
      date: 'Date',
      time: 'Time',
      price: 'Price',
      finalPrice: 'Costs',
      total: 'Total',
      yourData: 'Your data',
      disclaimer: 'Please enter your contact details. All fields marked with * are mandatory.',
      cart: {
        emptyCartWarning: 'There are currently no events in the cart.',
        waiting_list: 'Waiting list'
      }
    },
    finalpage: {
      successTitle: 'The registration was successfully submitted.',
      successMessageDu: 'The event registration was successfully submitted. A confirmation was sent either via e-mail or via SMS. <br> <br> If for any reason you can not attend the event, we ask you to cancel participation via the cancellation link in the email or in the SMS.',
      successMessageSie: 'The event registration was successfully submitted. A confirmation was sent either via e-mail or via SMS. <br> <br> If for any reason you can not attend the event, we ask you to cancel participation via the cancellation link in the email or in the SMS.',
      errorTitle: 'An error has occurred.',
      errorMessage: 'An error occurred during registration. Please try again.',
      bookNextEvent: 'Book further registration',
      bookEventAgain: 'Go back'
    }
  },
  resources: {
    noResources: 'This provider did not specify any resources yet.',
    step1: {
      title: 'Resources',
      chooseResource: 'Choose Resources'
    },
    step2: {
      title: 'Date',
      startDate: 'Start date: ',
      endDate: 'End date: ',
      startTime: 'Please select start time',
      endTime: 'Please select end time',
      resetCalendar: 'Reset calendar',
      noFreeResources: 'No bookable resources found for daterange',
      selectDate: 'Select date or daterange'
    },
    step3: {
      title: 'Finish',
      summary: 'Summary',
      company: 'Company',
      store: 'Store',
      employee: 'Resource',
      bookedEvent: 'Event',
      date: 'Date',
      time: 'Time',
      price: 'Price',
      finalPrice: 'Costs',
      total: 'Total',
      yourData: 'Your data',
      disclaimer: 'Please enter your contact details. All fields marked with * are mandatory.',
      prename: {
        label: 'First name',
        placeholder: 'First name',
        error: {
          minChars: 'The first name must be at least {{ requiredLength }} characters be long.',
          required: 'Please state first name.'
        }
      },
      lastname: {
        label: 'Last name',
        placeholder: 'Last name',
        error: {
          minChars: 'The last name must be at least {{ requiredLength }} characters be long.',
          required: 'Please enter last name.'
        }
      },
      phone: {
        label: 'Mobilephone',
        placeholder: '+4179xxxxxxx',
        error: {
          pattern: 'The telephone number may only contain numbers.',
          minChars: 'The phone number must be at least {{ requiredLength }} Contain characters.',
          required: 'Please enter a phone number.'
        }
      },
      eMail: {
        label: 'e-Mail',
        error: {
          pattern: 'Please enter a valid e-mail address.',
          minChars: 'The e-mail address must be at least {{ requiredLength }} characters be long.',
          required: 'Please enter an e-mail address.'
        }
      },
      message: {
        label: 'Message',
        placeholder: ''
      },
      customFields: {
        defaultOption: 'Choose any option',
        error: {
          minChars: 'This field requires {{ requiredLength }} characters',
          required: 'This field is mandatory'
        }
      },
      cart: {
        emptyCartWarning: 'There are currently no resources in the cart.'
      },
      From: 'From',
      To: 'To'
    },
    finalpage: {
      successTitle: 'You successfully booked resource(s)',
      successMessageDU: 'In case you change your mind please cancel your resource booking at least {{ leadTime }} hours before you booked them.',
      successMessageSIE: 'In case you change your mind please cancel your resource booking at least {{ leadTime }} hours before you booked them.',
      errorTitle: 'An error has occurred.',
      errorMessage: 'The resource could not be booked. Please try again.',
      bookNextEvent: 'Book additional resources',
      bookEventAgain: 'Go back'
    }
  },
  customer: {
    prename: {
      label: 'First name',
      placeholder: 'First name',
      error: {
        minChars: 'The first name  must be at least {{ requiredLength }} chars long.',
        required: 'Please enter your first name.'
      }
    },
    lastname: {
      label: 'Last name',
      placeholder: 'Last name',
      error: {
        minChars: 'The last name must be at least {{ requiredLength }} chars long.',
        required: 'Please enter your last name.'
      }
    },
    phone: {
      label: 'Phone number',
      placeholder: '+4179.......',
      error: {
        pattern: 'The phone number must contain only numbers.',
        minChars: 'The phone number must contain at least {{ requiredLength }} numbers.',
        required: 'Please enter your phone number.',
        invalid: 'Please enter a valid mobile number.'
      }
    },
    eMail: {
      label: 'Email',
      error: {
        pattern: 'Please enter a valid email address.',
        minChars: 'The email address must contain at least {{ requiredLength }} chars.',
        required: 'An email address is required.'
      }
    },
    street: {
      label: 'Street / No.',
      placeholder: 'Street Name',
      error: {
        minChars: 'The street must contain at least {{ requiredLength }} characters.',
        required: 'Please enter your street name.'
      }
    },
    zip: {
      label: 'ZIP',
      placeholder: 'Zip code',
      error: {
        minChars: 'Zip number must contain at least {{ requiredLength }} characters.',
        required: 'Please enter your zip number.',
        pattern: 'Please enter a valid zip code.'
      }
    },
    city: {
      label: 'City',
      placeholder: 'City Name',
      error: {
        minChars: 'The city must contain at least {{ requiredLength }} characters.',
        required: 'Please enter your city name.'
      }
    },
    message: {
      label: 'Message',
      placeholder: ''
    },
    customFields: {
      defaultOption: 'Select an option',
      error: {
        minChars: 'This field requires at least {{ requiredLength }} chars.',
        required: 'This field is required.'
      }
    },
    captcha: {
      required: 'This field is required.',
      invalid: 'Please enter valid captcha.',
      valid: 'Captcha matched successfully.',
      placeholder: 'Type the text shown above'
    }
  },
  stripe: {
    stripe_title: 'Pay your next visit online',
    submit_payment: 'Submit Payment',
    stripe_label: 'Credit card or debit card',
    online_payment_optional: 'In this step, you have the opportunity to settle your next visit right now. There are no additional charges for you. The payment is made via a secure provider (Stripe). We do not store credit card information on our servers.',
    online_payment_mandatory: 'To complete the booking, we kindly ask you to pay your next visit now. There are no additional charges for you. The payment is made via a secure provider (Stripe). We do not store credit card information on our servers.'
  },
  alert_messages: {
    select_day_in_calendar_msg: "Select a specific day in the calendar to get possible dates suggested.",
    no_events_in_month: "Unfortunately there are no more free time slots in the selected month.",
    no_events: "Unfortunately, this event has no bookable windows in the future. Therefore, no calendar is displayed to select a date.",
    waitinglist_enabled_message: "Some time slots are already fully booked. However, registration on the waiting list is possible. As soon as a participation is cancelled, the next waiting list place moves up and receives a confirmation of participation.",
    waitinglist_booking_message: "Many Thanks! We have successfully accepted your booking. Since you are now on the waiting list for at least one event, you will only receive a confirmation of participation from us if another participant can not attend the event. In this case, you will be notified by e-mail. Unfortunately, due to lack of space, you will not be able to attend the event without an official confirmation from our site.",
    window_is_fully_booked: "This time window is fully booked (including waiting list).",
    waitinglist_is_available: "This time slot is fully booked, but still has <b>{{ number }}</b> available spots on the waiting list.",
    no_bookable_dates_message: "Unfortunately there are no more bookable days in the month {{ month }}. Should we search for free dates in future months?",
    no_slots_dates_message: "Unfortunately there are no more bookable time slots in the month {{ month }}. Should we search future months for free time slots?",
    no_slots_dates_reached_message: "Unfortunately there are no more bookable time slots in the month {{ month }}. The maximum booking period has been reached.",
    no_bookable_dates_reached_message: "Unfortunately there are no more bookable dates in the month {{ month }}. The maximum booking period has been reached.",
    xss_error_message: "This text contains unsupported HTML tags."
  },
  coupon_credit_card_info: {
    redeem_coupon_1: 'Redeem coupon',
    apply_coupon: 'Apply Coupon',
    coupon: 'Coupon',
    credit_or_debit_card: 'Credit or debit card',
    invalid_coupon: 'Invalid Coupon',
    applied_successfully: 'Applied successfully'
  },
  tax_info: {
    taxes: '(incl. {{percentage}}% VAT)'
  },
  new_alert_component: {
    x_day_ago: 'x day ago',
    x_days_ago: 'x days ago',
    x_minute_ago: 'x minute ago',
    x_minutes_ago: 'x minutes ago',
    x_hour_ago: 'x hour ago',
    x_hours_ago: 'x hours ago',
    x_second_ago: 'x second ago',
    x_seconds_ago: 'x seconds ago',
    x_week_ago: 'x week ago',
    x_weeks_ago: 'x weeks ago',
    x_month_ago: 'x month ago',
    x_months_ago: 'x months ago',
    x_year_ago: 'x year ago',
    x_years_ago: 'x years ago'
  },
  summary_page_translations: {
    paypal_warning_msg: 'Before a booking is possible, the open amount must be paid with PayPal.',
    paypal: 'PayPal',
    saferpay_payment_overlay: 'This screen will hide automatically once the payment has been completed or canceled.<br/>In case the payment screen closed accidentally, kindly tap the close button to hide it and reinitiate payment.',
    or: 'or',
    browser: 'Browse',
    choose_file: 'Choose file',
    clear: 'Clear',
    meeting_type: 'Where should the appointment take place?',
    email: 'Email',
    sms: 'SMS',
    both_sms_email: 'Email and SMS',
    notification_type: 'Notification type',
    payment_title: 'Payment',
    payment_subtitle: 'Decide how you want to pay for your services',
    payment_subtitle_sie: 'Decide how you want to pay for your services',
    summary_title: 'Summary',
    summary_subtitle: 'Check your order and submit it',
    summary_subtitle_sie: 'Check your order and submit it',
    payment_type: 'Payment Type',
    creditcard: 'Credit Card',
    invoice: 'Invoice',
    store: 'On Site',
    apply_coupon: 'I would like to apply a discount code',
    choose_payment_method: 'Choose payment method',
    tax: 'incl. {{ rate }}% VAT',
    subtotal: 'Subtotal',
    personal_form_title: 'Personal Data',
    personal_form_subtitle: 'Tell us something about you',
    personal_form_subtitle_sie: 'Tell us something about you',
    inclusive: 'inclusive',
    paymentMandatory: 'The provider has defined that the service must be paid online in advance. Therefore please select a payment method.',
    file_upload: 'File Upload',
    file_upload_helper_text: 'Multiple files can be attached',
    file_was_uploaded: '"{{name}}" was uploaded successfully',
    max_file_size_5mb: 'The uploaded file is too big (maximum 5MB are allowed).',
    malware_found: 'A problem occurred while scanning the file for viruses (Access denied). Please contact Calenso Support!',
    multiple_file_upload_helper_text: 'A maximum of 5 files can be uploaded. The file to be uploaded must not be larger than 5 MB.',
    single_file_upload_helper_text: '1 file can be uploaded. The file to be uploaded must not be larger than 5 MB.',
    file_uploading: 'The file upload is still in progress. Please wait until the action is finished and then try to book again.',
    maximum_x_files: 'A maximum of {{ fileLength }} files can be uploaded.',
    document_invalid_file_type: "Invalid file type. Only PDF and Excel files are allowed.",
    image_invalid_file_type: "Invalid file type, please upload a valid image file.",
    request_sms_code: 'Request code',
    mobile_number_must_be_verified: 'The number must be verified for the booking.',
    verification_code: 'Verification code',
    sms_code_verification_failed_msg: 'The inserted code unfortunately does not match the code we sent by SMS.',
    check: 'Check',
    phone_number_verified_successfully: 'The number has been successfully verified.',
    resend_sms_helper_msg_1: 'I have not received a code.',
    resend_sms_helper_msg_2: 'Request code again.',
    resend_sms_successfully: 'SMS code resent successfully',
    exceeded_sms_verification_limit_msg: 'This number has exceeded the maximum number of verification codes. Please contact Calenso support (<a href="mailto:support@calenso.com">support@calenso.com</a>).',
    salutation: 'Salutation',
    mr: 'Mr',
    ms: 'Ms',
    other: 'other',
    select_payment_method: 'Please pay the open amount using PayPal first, before you can submit the booking.',
    accept_data_policy: 'I agree to and have read <a href="https://calenso.com/agb/" target="_blank">Calenso\'s Terms & Conditions</a> & <a href="https://calenso.com/datenschutz/" target="_blank">Privacy Policy</a> for the use of my data in connection with the appointment booking.',
    saferpay: 'Saferpay',
    saferpay_pay: 'Tap to pay using Saferpay',
    payment_success_title: 'Successful payment',
    payment_success_message: 'The open amount has been paid successfully with {{ paymentMethod }}. The appointment can now be booked. If there is no booking, no amount will be charged.',
    title: 'Title',
    country: 'Country',
    title_aria_describedby: 'Special title that supports Dr., Dr. med., Prof.',
    salutation_aria_describedby: 'Form of salutation that supports Mr., Mrs and other',
    prename_aria_describedby: 'Pre name or First name',
    lastname_aria_describedby: 'Last name',
    notification_type_aria_describedby: 'To can define in the booking process, how you will be notified about the booking.',
    email_describedby: 'Email',
    phoneNumber_aria_describedby: 'Phone number',
    smsPhoneCode_aria_describedby: 'OTP to verify phone number',
    mobileNumber_aria_describedby: 'Mobile number',
    smsMobileCode_aria_describedby: 'OTP to verify mobile number',
    street_aria_describedby: "Address",
    zip_aria_describedby: "ZIP Code",
    city_aria_describedby: "City",
    country_aria_describedby: "Country",
    comment_aria_describedby: "Additional comments if you any",
    internal_comment_aria_describedby: "Internal comment for (internal use only)",
    company_aria_describedby: "Company name",
    captcha_aria_describedby: "Captcha"
  },
  buttons: {
    yes_search: 'Yes, search.',
    remove: 'Remove'
  },
  add: 'Add',
  compact: {
    no_service: 'No appointments can currently be booked because no matched service is found.',
    navigation: {
      date: 'Dates',
      date_slots: 'Dates and Slots',
      personal_info: 'Personal Data'
    }
  }
};
