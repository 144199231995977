import { DecimalPipe, NgClass } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { APPOINTMENT_CONSTANT } from '../../../../constants/appointment-constants';
import { TRANSLATION_TEMPLATES } from '../../../../constants/translation-templates-constants';
import { PARENT_TO_CHILD_IFRAME_EVENTS, PARENT_TO_CHILD_IFRAME_EVENT_ACTIONS, PARENT_TO_CHILD_IFRAME_VALIDATION_CONTROL_NAME, WIDGET_CONSTANTS } from '../../../../constants/widget-constants';
import { AppointmentServiceCategoryDao, AppointmentServiceDao } from '../../../../db-models/appointment-service-dao';
import { StoresDao } from '../../../../db-models/stores-dao';
import { CustomBookingMessageTemplate } from '../../../../db-models/widget-conf-dao';
import { GlobalObjects, Partner } from '../../../../models/global';
import { AppointmentState, ConferenceState } from '../../../../models/state.model';
import { WidgetColorConf } from '../../../../models/widget-color.model';
import { CustomEventService } from '../../../../services/custom-event.service';
import { FormsService } from '../../../../services/forms.service';
import { LoggerService } from '../../../../services/logger.service';
import { WidgetUtilService } from '../../../../services/widget-util.service';
import { ButtonComponent } from '../../../../shared/components/button/button.component';
import { ResetButtonComponent } from '../../../../shared/components/reset-button/reset-button.component';
import { TranslationPipe } from '../../../../shared/pipes/translation.pipe';
import { TrustHtmlPipe } from '../../../../shared/pipes/trust-html.pipe';
import { CwCardComponent } from '../../../common/theme/cw-card/cw-card.component';
import { CwRadioTickButtonComponent } from '../../../common/theme/cw-radio-tick-button/cw-radio-tick-button.component';
import { CwTitleComponent } from '../../../common/theme/cw-title/cw-title.component';
import { AppointmentServicesComponent } from '../appointment-services/appointment-services.component';

@Component({
  selector: 'app-appointment-list',
  templateUrl: './appointment-list.component.html',
  styleUrls: ['./appointment-list.component.scss'],
  standalone: true,
  imports: [ResetButtonComponent, CwTitleComponent, NgSelectModule, FormsModule, ButtonComponent, CwCardComponent, NgClass, CwRadioTickButtonComponent, TrustHtmlPipe,
    AppointmentServicesComponent, DecimalPipe, TranslateModule, TranslationPipe],
})

export class AppointmentListComponent implements OnInit, OnChanges {

  @Input() globals: GlobalObjects;
  @Input() stores: StoresDao[] = [];
  @Input() hideStoreSelection = false;
  @Input() appointmentState: AppointmentState;
  @Input() widgetConf: any;
  @Input() selectedStore: StoresDao;
  @Input() widgetTemplates: CustomBookingMessageTemplate[] = [];
  @Input() appoinrmentServicesByCat: {
    category: string,
    _translations: any,
    is_multi_language: number,
    appointmentServiceCategory: AppointmentServiceCategoryDao,
    aServicesOfCat: AppointmentServiceDao[],
    position: number
  }[];
  @Input() partner: Partner;
  @Input() noWorkers: boolean;
  @Input() lang: string;
  @Input() widgetColorConf: WidgetColorConf;
  @Input() previousButtonTemplate: CustomBookingMessageTemplate;
  @Input() nextButtonTemplate: CustomBookingMessageTemplate;
  @Input() selectedStoreZipCode: number;
  @Input() subscriptionTypeId: number;
  @Input() conferenceState: ConferenceState;

  @Output() navigateToEvent: EventEmitter<any> = new EventEmitter();
  @Output() singleServiceChangeEvent: EventEmitter<number> = new EventEmitter();
  @Output() storeSelectedEvent: EventEmitter<number> = new EventEmitter();
  @Output() resetFormEvent: EventEmitter<any> = new EventEmitter();
  @Output() serviceChangeEvent: EventEmitter<any> = new EventEmitter();
  @Output() disabledServiceTabEvent: EventEmitter<any> = new EventEmitter();
  @Output() newStoresEvent: EventEmitter<StoresDao[]> = new EventEmitter();

  readonly appointmentConstant = APPOINTMENT_CONSTANT;
  readonly widgetConstant = WIDGET_CONSTANTS;
  readonly environment = environment;
  readonly parentToChildIframeEvents = PARENT_TO_CHILD_IFRAME_EVENTS;
  readonly parentToChildIframeValidationControlName = PARENT_TO_CHILD_IFRAME_VALIDATION_CONTROL_NAME;
  readonly templateContent = TRANSLATION_TEMPLATES;

  activePanels = ['static-0'];
  cartSupported: boolean;
  isMobile: boolean;
  isStoreLoading = false;
  storeLabelTemplate: CustomBookingMessageTemplate;
  leadGeneratorPostcode: string;
  searchStores = new EventEmitter<string>();
  parentToChildIframeEventActions = PARENT_TO_CHILD_IFRAME_EVENT_ACTIONS;

  constructor(
    public translate: TranslateService,
    private widgetUtilService: WidgetUtilService,
    private formsService: FormsService,
    private customEventService: CustomEventService
  ) {
    this.translate.onLangChange.subscribe(lang => this.lang = lang.lang);
  }

  ngOnInit(): void {
    this.customEventService.iFrameNavigationFromParent.subscribe({
      next: (action: string) => {
        switch (action) {
          case this.parentToChildIframeEventActions.PREVIOUS:
            if (this.partner?.is_customer_profiles_feature_enabled === 1 && this.partner?.customer_profiles?.length > 1) {
              this.navigateTo(this.appointmentConstant.CUSTOMER_TYPE);
            } else if (this.partner?.is_customer_profiles_feature_enabled !== 1) {
              this.widgetUtilService.sendMessageToParent({
                eventName: this.parentToChildIframeEvents.VALIDATION,
                controls: [
                  this.parentToChildIframeValidationControlName.CUSTOMER_PROFILE_DISABLED
                ]
              });
            }
            break;
          case this.parentToChildIframeEventActions.NEXT:
            if (this.appointmentState.services?.length) {
              this.navigateTo(this.appointmentConstant.WORKER, false);
            } else if (this.appointmentState.store === 0 || this.appointmentState.store === null) {
              this.widgetUtilService.sendMessageToParent({
                eventName: this.parentToChildIframeEvents.VALIDATION,
                controls: [
                  this.parentToChildIframeValidationControlName.STORE,
                  this.parentToChildIframeValidationControlName.SERVICE
                ]
              });
            } else if (this.appointmentState.services?.length === 0 || this.appointmentState.store === null) {
              this.widgetUtilService.sendMessageToParent({
                eventName: this.parentToChildIframeEvents.VALIDATION,
                controls: [
                  this.parentToChildIframeValidationControlName.SERVICE
                ]
              });
            }
            break;
        }
      }
    });

    let tempPostCode: string;

    this.translate.onLangChange.subscribe(lang => this.lang = lang.lang);

    this.searchStores.pipe(
      distinctUntilChanged(),
      debounceTime(300),
      switchMap((term: string) => {
        this.isStoreLoading = true;
        tempPostCode = term;
        if (term && term.trim() !== '' && String(term).length >= 4) {
          this.leadGeneratorPostcode = term;
          this.stores = [];
          let eanbleStoreSorting = true;

          // Check if PLZ/ZIP search is enable then don't sort
          if (
            this.partner?.enable_store_postcode_feature === 1 ||
            this.partner?.automatic_store_zip_search === 1
          ) {
            eanbleStoreSorting = false;
          }
          return this.formsService.getStoresByPostCode(term, eanbleStoreSorting);
        } else {
          this.stores = [];
          return of([]);
        }
      })
    ).subscribe({
      next: stores => {
        this.stores = stores;
        this.newStoresEvent.emit(stores);
        if (this.stores?.length === 1) {
          this.appointmentState.store = this.stores[0].id;
          this.onStoreSelected(this.stores[0]);
        } else if (this.stores?.length > 1) {
          const filteredStores = this.stores?.filter(store => Number(tempPostCode) === Number(store.zip));
          if (filteredStores.length === 1) {
            this.appointmentState.store = filteredStores[0].id;
            this.onStoreSelected(filteredStores[0]);
          }
        }
        this.isStoreLoading = false;
      },
      error: (error: HttpErrorResponse) => {
        LoggerService.error(error);
        this.isStoreLoading = false;
      }
    });

    if (
      (this.partner.enable_store_postcode_feature === 1 || this.partner.automatic_store_zip_search === 1)
      && !this.appointmentState.store
      && (
        (this.globals?.isInternal && this.partner?.internal_widget_store_search_list === 0)
        ||
        (!this.globals?.isInternal)
      )
    ) {
      this.stores = [];
      if (this.selectedStoreZipCode) {
        this.searchStores.emit(this.selectedStoreZipCode + '');
      }
    }
  }

  allowOnlyNumbers(event: KeyboardEvent): boolean {
    const numReg = /^[0-9]$/;
    if (numReg.test(event.key)) {
      return true;
    }
    // Only ASCII charactar in that range allowed
    const ASCIICode = (event.which) ? event.which : event.keyCode;

    if (ASCIICode === 8) {
      return true;
    }

    if (ASCIICode === 65 && event.metaKey) {
      return true;
    }

    return false;
  }

  zipCode(searchTerm: string): void {
    this.widgetUtilService.zipCode = searchTerm;
  }

  onStoreSelected(store: StoresDao): void {
    if (!store?.id) {
      return;
    }

    this.appointmentState.store = store.id;
    if (store.default_worker_id) {
      this.appointmentState.default_worker_id = store.default_worker_id;
      this.appointmentState.default_worker = store.default_worker;
    } else {
      this.appointmentState.default_worker_id = null;
      this.appointmentState.default_worker = null;
    }
    this.appointmentState.leadGeneratorPostcode = this.leadGeneratorPostcode;
    this.storeSelectedEvent.emit(store.id);
  }

  resetForm(): void {
    this.resetFormEvent.emit();
  }

  onSingleServiceChange(event: any, serviceId: number): void {
    this.singleServiceChangeEvent.emit(serviceId);
  }

  navigateTo(page: string, isFirstTime?: boolean): void {
    // In case of conference skip worker selection and move to date step
    if (page === this.appointmentConstant.WORKER && this.conferenceState?.uuid) {
      page = this.appointmentConstant.DATE;
    }

    this.navigateToEvent.next({ page: page, isFirstTime: isFirstTime });
  }

  shortcutNavigateTo(): void {
    if (this.partner?.is_customer_profiles_feature_enabled === 1 && this.partner?.customer_profiles?.length > 1) {
      this.navigateTo(this.appointmentConstant.CUSTOMER_TYPE);
    } else {
      this.navigateTo(this.appointmentConstant.WORKER, false);
    }
  }

  getSelectedStore(storeId: number): StoresDao {
    if (storeId) {
      const founded = this.stores.find(store => store.id === storeId);
      if (founded) {
        this.selectedStore = founded;
        return founded;
      } else {
        return undefined;
      }
    }
    return undefined;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getSelectedStore(this.appointmentState.store);

    if (changes?.widgetTemplates?.currentValue) {
      this.widgetTemplates?.length && this.setupTemplates();
    }
  }

  setupTemplates(): void {
    this.storeLabelTemplate = this.widgetTemplates.find(template =>
      template.identifier === WIDGET_CONSTANTS.WIDGET_LABELS.WIDGET_APPOINTMENT_STORE_LABEL
    );
    this.storeLabelTemplate && (this.storeLabelTemplate.is_multi_language = 1);
  }
}
